import "./index.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("Política de cookies")} />

      <PatternLateral />
      <h2 className="sectionTitle">
        <Trans>Política de cookies</Trans>
      </h2>

      <div className="mainText legalBody" id="formTop">
        <h3 className="basic">
          <b>Normativa sobre el uso de cookies</b>
        </h3>
        Esta Política de Cookies es parte integrante del Aviso Legal y la
        Política de Privacidad. El acceso y la navegación en el sitio, o el uso
        de los servicios del mismo, implican la aceptación de las Condiciones
        Generales establecidas en nuestro Aviso Legal (y por tanto de la
        Política de Privacidad y Política de Cookies). Por favor, léelas
        atentamente.
        <br />
        <br />
        Le agradecemos que haya decidido visitarnos. Queremos que su experiencia
        en el sitio sea lo mejor posible, y por ese motivo hemos redactado, en
        cumplimiento del apartado segundo del artículo 22 de la Ley 34/2002, de
        11 de julio, de servicios de la sociedad de la información y de comercio
        electrónico (en adelante, LSSI), esta Política de Cookies de la forma
        más clara, transparente, completa y comprensible posible.
        <br />
        <br />
        <h3 className="basic">
          <b>&nbsp;</b>
          <b>¿Qué es una Cookie?</b>
        </h3>
        Este sitio web utiliza cookies y/o tecnologías similares que almacenan y
        recuperan información cuando navega. En general, estas tecnologías
        pueden servir para finalidades muy diversas, como, por ejemplo,
        reconocerle como usuario, obtener información sobre sus hábitos de
        navegación, o personalizar la forma en que se muestra el contenido.
        <br />
        <br />
        Las cookies son archivos que contienen pequeñas cantidades de
        información que se descargan en el navegador o en el dispositivo del
        usuario que se utiliza (smartphone, tablet o televisión conectada)
        cuando visita un sitio web. Su finalidad principal es almacenar
        información sobre su visita y reconocer al usuario cada vez que accede
        al sitio web. Nos permite, además, mejorar la calidad y la usabilidad de
        nuestra web.
        <br />
        <br />
        Las cookies son esenciales para el funcionamiento de Internet; no pueden
        dañar el equipo/dispositivo del usuario y, si se encuentran activadas en
        la configuración de tu navegador, nos ayudan a identificar y resolver
        posibles errores de funcionamiento de la web.
        <br />
        <br />
        Como la mayoría de los sitios en internet, este sitio web utiliza
        Cookies para asegurar que la página web funcione correctamente, para
        almacenar las preferencias, como, por ejemplo, el idioma que ha
        seleccionado o el tamaño de letra, para conocer la experiencia de
        navegación del usuario, para recopilar información estadística anónima,
        como, por ejemplo, qué páginas ha visitado el usuario o cuánto tiempo ha
        permanecido en el sitio web.
        <br />
        <br />
        <h3 className="basic">
          <b>&nbsp;</b>
          <b>Tipos de Cookies</b>
        </h3>
        Hay diferentes tipos de cookies. Todos ellos trabajan de la misma
        manera, pero tienen pequeñas diferencias:
        <br />
        <br />
        <ul>
          <li>
            <u>Cookies de Sesión</u>: son aquellas diseñadas para recabar y
            almacenar datos mientras el usuario accede a una página web. Se
            suelen emplear para almacenar información que solo interesa
            conservar para la prestación del servicio solicitado por el usuario
            en una sola ocasión (por ejemplo, una lista de productos adquiridos)
            y desaparecen al terminar la sesión. Las cookies de sesión duran
            solamente por la duración de su visita y se borran cuando cierra el
            navegador. Su finalidad principal es identificar el tipo de
            dispositivo, apoyar la seguridad sitio web o su funcionalidad
            básica. No contienen información personal que nos permita
            identificar a una persona.
          </li>
          <li>
            <u>Cookies Persistentes o Permanentes</u>: son aquellas en las que
            los datos siguen almacenados en el terminal y pueden ser accedidos y
            tratados durante un periodo definido por el responsable de la
            cookie, y que puede ir de unos minutos a varios años. Se almacenan
            en el disco duro del dispositivo y nuestra web las lee cada vez que
            realiza una nueva visita al sitio web; posee una fecha de caducidad
            o expiración determinada, cumplida la cual la cookie deja de
            funcionar. Nos permiten identificar sus acciones y preferencias;
            analizar las visitas y nos ayudan a comprender cómo llegan los
            usuarios a nuestra página y mejorar nuestros servicios.
          </li>
          <li>
            <u>Cookies Técnicas, estrictamente necesarias</u>:{" "}
            <em>
              (exceptuadas del cumplimiento de las obligaciones establecidas en
              el artículo 22.2 de la LSSI cuando permitan prestar el servicio
              solicitado por el usuario)
            </em>
            . Son aquellas que permiten al usuario la navegación a través de una
            página web, plataforma o aplicación y la utilización de las
            diferentes opciones o servicios que en ella existan, incluyendo
            aquellas que el editor utiliza para permitir la gestión y operativa
            de la página web y habilitar sus funciones y servicios, como, por
            ejemplo, controlar el tráfico y la comunicación de datos,
            identificar la sesión, acceder a partes de acceso restringido,
            recordar los elementos que integran un pedido, realizar el proceso
            de compra de un pedido, gestionar el pago, controlar el fraude
            vinculado a la seguridad del servicio, realizar la solicitud de
            inscripción o participación en un evento, contar visitas a efectos
            de la facturación de licencias del software con el que funciona el
            servicio (sitio web, plataforma o aplicación), utilizar elementos de
            seguridad durante la navegación, almacenar contenidos para la
            difusión de vídeos o sonido, habilitar contenidos dinámicos (por
            ejemplo, animación de carga de un texto o imagen) o compartir
            contenidos a través de redes sociales.
          </li>
          <li>
            <u>Cookies de preferencias o personalización</u>: son aquellas que
            permiten recordar información para que el usuario acceda al servicio
            con determinadas características que pueden diferenciar su
            experiencia de la de otros usuarios, como, por ejemplo, el idioma,
            el número de resultados a mostrar cuando el usuario realiza una
            búsqueda, el aspecto o contenido del servicio en función del tipo de
            navegador a través del cual el usuario accede al servicio o de la
            región desde la que accede al servicio, etc.
          </li>
          <li>
            <u>Cookies de geo-localización</u>: se utilizan para averiguar en
            qué país o región se encuentra el usuario cuando accede a un
            servicio del sitio web con el fin de ofrecerle contenidos o
            servicios adecuados a su localización.
          </li>
          <li>
            <u>Cookies de análisis o medición</u>: son aquellas que permiten al
            responsable de las mismas el seguimiento y análisis del
            comportamiento de los usuarios de los sitios web a los que están
            vinculadas, incluida la cuantificación de los impactos de los
            anuncios. La información recogida mediante este tipo de cookies se
            utiliza en la medición de la actividad de los sitios web, aplicación
            o plataforma, con el fin de introducir mejoras en función del
            análisis de los datos de uso que hacen los usuarios del servicio.
          </li>
          <li>
            <u>Cookies de publicidad comportamental</u>: son aquellas que
            almacenan información del comportamiento de los usuarios obtenida a
            través de la observación continuada de sus hábitos de navegación, lo
            que permite desarrollar un perfil específico para mostrar publicidad
            en función del mismo.
            <ul>
              <li>
                &nbsp;Anónimas: sólo recopilan información sobre los espacios
                publicitarios mostrados en el sitio web, con independencia del
                usuario que accede al sitio web, es decir, sin identificarle
                expresamente.
              </li>
              <li>
                Personalizadas: recopilan información personal del usuario del
                sitio web por parte de un tercero, para la personalización de
                dichos espacios publicitarios.
              </li>
            </ul>
          </li>
          <li>
            <u>Cookies de Funcionalidad</u>: permiten recordar decisiones
            adoptadas por el usuario, como su login o identificador. La
            información de estas cookies recogen se anonimiza (es decir, no
            contiene ni tu nombre, dirección, u otros datos).
          </li>
          <li>
            <u>Cookies Propias</u>: son aquellas que se envían al equipo
            terminal del usuario desde un equipo o dominio gestionado por el
            propio editor y desde el que se presta el servicio solicitado por el
            usuario.
          </li>
          <li>
            <u>Cookies de Terceros</u>: las cookies de terceros son las cookies
            que instala un sitio web que no es el que estás visitando; por
            ejemplo, las usadas por redes sociales (como Facebook) o por
            complementos externos de contenido (como Google Maps). Además,
            algunas empresas de publicidad usan este tipo de archivos para
            realizar un seguimiento de tus visitas en cada sitio en el que se
            anuncian.
          </li>
        </ul>
        <h3 className="basic">
          <b>Uso de Cookies</b>
        </h3>
        Mediante el acceso a la web acepta de manera expresa que podamos usar
        este tipo de cookies en sus dispositivos. Si desactiva las cookies,
        puede que su navegación por no sea óptima y algunas de las utilidades
        que dispone no funcionen correctamente.
        <br />
        <br />
        <b>a) Cookies propias.</b>
        <br />
        <br />
        Con el fin de reconocerle y prestarle un mejor servicio, nuestro sitio
        utiliza cookies propias (pequeños archivos de texto que su navegador
        almacena). Las ventajas que conlleva la aceptación de nuestras cookies
        se traduce en un ahorro de tiempo.
        <br />
        <br />
        Asimismo pueden ser utilizadas también para reconocerle entre visitas
        sucesivas y así adaptar el contenido que se le muestra, para obtener
        información acerca de la fecha y hora de su última visita, medir algunos
        parámetros de tráfico dentro del propio sitio, y estimar el número de
        visitas realizadas, de manera que podamos enfocar y ajustar los
        servicios y promociones de forma más efectiva.
        <br />
        <br />
        Ninguna cookie permite que pueda contactarse con su número de teléfono,
        su dirección de correo electrónico o con cualquier otro medio de
        contacto. Ninguna cookie puede extraer información de su disco duro o
        robar información personal.
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <td colspan="2" width="558">
                COOKIES PROPIAS Y ESTRICTAMENTE NECESARIAS
              </td>
            </tr>
            <tr>
              <td width="134">Nombre</td>
              <td width="424">Propósito y duración</td>
            </tr>
            <tr>
              <td width="134"></td>
              <td width="424"></td>
            </tr>
            <tr>
              <td width="134"></td>
              <td width="424"></td>
            </tr>
            <tr>
              <td width="134"></td>
              <td width="424"></td>
            </tr>
          </tbody>
        </table>
        <br />
        <b>b) Cookies de Terceros.</b>
        <br />
        <br />
        Son aquellas que se envían al equipo terminal del usuario desde un
        equipo o dominio que no es gestionado por el editor, sino por otra
        entidad que trata los datos obtenidos través de las cookies.
        <br />
        <br />
        <b>Cookies de Google Analytics</b>
        <br />
        <br />
        El sitio utiliza el servicio Google Analytics, que es prestado por
        Google, Inc., entidad cuya oficina principal está en 1600 Amphitheatre
        Parkway, Mountain View (California), CA 94043, Estados Unidos
        («Google»).
        <br />
        <br />
        Google Analytics utiliza cookies para ayudarnos a analizar el uso que
        hacen los usuarios del sitio. La información que genera la cookie acerca
        de su uso del sitio (incluyendo su dirección IP) será directamente
        transmitida y archivada por Google en sus servidores de Estados Unidos.
        Google usará esta información por cuenta nuestra con el propósito de
        seguir la pista de su uso del sitio, recopilando informes de la
        actividad del sitio y prestando otros servicios relacionados con la
        actividad del sitio y el uso de Internet. Google podrá transmitir dicha
        información a terceros cuando así se lo requiera la legislación, o
        cuando dichos terceros procesen la información por cuenta de Google.
        Google no asociará su dirección IP con ningún otro dato del que disponga
        Google.
        <br />
        <br />
        Si lo desea puede rechazar el tratamiento de los datos o la información
        rechazando el uso de cookies mediante la selección de la configuración
        apropiada de su navegador. Sin embargo, si lo hace, puede ser que no
        pueda usar la plena funcionabilidad de este sitio. Al utilizar este
        sitio consiente el tratamiento su información por Google en la forma y
        para los fines arriba indicados.
        <br />
        <br />
        Más información en{" "}
        <a href="https://www.google.es/intl/es/policies/technologies/cookies/">
          https://www.google.es/intl/es/policies/technologies/cookies/
        </a>
        .<br />
        Google ha desarrollado un complemento que puede instalar en su navegador
        si desea no ser rastreado por sus cookies:{" "}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <td colspan="2" width="558">
                <b>COOKIES ANALÍTICAS</b>
              </td>
            </tr>
            <tr>
              <td width="73">Nombre</td>
              <td>Propósito</td>
            </tr>
            <tr>
              <td width="73">_utma</td>
              <td>
                Se usa para distinguir usuarios y sesiones. La cookie se crea
                cuando se ejecuta la biblioteca JavaScript y no hay ninguna
                cookie __utma. La cookie se actualiza cada vez que se envían
                datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td width="73">_utmt</td>
              <td>Se usa para limitar el porcentaje de solicitudes.</td>
            </tr>
            <tr>
              <td width="73">_utmb</td>
              <td>
                Se usa para determinar nuevas sesiones o visitas. La cookie se
                crea cuando se ejecuta la biblioteca JavaScript y no hay ninguna
                cookie __utmb. La cookie se actualiza cada vez que se envían
                datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td width="73">_utmc</td>
              <td>
                No se usa en ga.js. Se configura para interactuar con urchin.js.
                Anteriormente, esta cookie actuaba junto con la cookie __utmb
                para determinar si el usuario estaba en una nueva sesión o
                visita.
              </td>
            </tr>
            <tr>
              <td width="73">_utmz</td>
              <td>
                Almacena la fuente de tráfico o la campaña que explica cómo ha
                llegado el usuario al sitio. La cookie se crea cuando se ejecuta
                la biblioteca JavaScript y se actualiza cada vez que se envían
                datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td width="73">_utmv</td>
              <td>
                Se usa para almacenar datos de variables personalizadas de
                visitante. Esta cookie se crea cuando un programador usa el
                método{" "}
                <a href="https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration?hl=es#_gat.GA_Tracker_._setCustomVar">
                  _setCustomVar
                </a>{" "}
                con una variable personalizada de visitante. También se usaba
                para el método _setVar retirado. La cookie se actualiza cada vez
                que se envían datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td width="73">_ga</td>
              <td>
                Se usa para diferenciar entre usuarios y sesiones. La cookie se
                crea al cargar la librería javascript y no existe una versión
                previa de la cookie _ga. La cookie se actualiza cada vez que
                envia los datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td width="73">_gat</td>
              <td>
                Se usa para diferenciar entre los diferentes objetos de
                seguimiento creados en la sesión. La cookie se crea al cargar la
                librería javascript y no existe una versión previa de la cookie
                _gat. La cookie se actualiza cada vez que envia los datos a
                Google Analytics.
              </td>
            </tr>
          </tbody>
        </table>
        {/* <br />
          <b>Otras Cookies de Terceros</b>
          <br />
          <br />
          <em>
            (Atención: cumplimentar sólo en el caso de que el sitio web inserte
            cookies de terceros con fines publicitarios. Además deberá de
            incluir un hiperenlace a la normativa de uso de cookies del
            anunciante).
          </em>
          <br />
          <br />
          <table>
            <tbody>
              <tr>
                <td colspan="2" width="558">
                  <b>OTRAS COOKIES DE TERCEROS</b>
                </td>
              </tr>
              <tr>
                <td width="73">Nombre</td>
                <td >Propósito y duración</td>
              </tr>
              <tr>
                <td width="73">
                  &nbsp;
                  <br />
                  <br />
                  &nbsp;
                  <br />
                  <br />
                  &nbsp;
                  <br />
                  <br />
                  &nbsp;
                  <br />
                  <br />
                  &nbsp;
                  <br />
                  <br />
                </td>
                <td ></td>
              </tr>
            </tbody>
          </table>
          <em>
            Puede informarse de las transferencias a terceros países que, en su
            caso, realicen los terceros identificados en esta política de
            cookies en sus correspondientes políticas.
          </em>
          <br /> */}
        <br />
        <h3 className="basic">
          <b>Configuración del usuario para evitar Cookies.</b>
        </h3>
        Desde la web y en cumplimiento de la normativa legal vigente, ponemos a
        su disposición la información que le permita configurar su
        navegador/navegadores de Internet para mantener su privacidad y
        seguridad en relación a las cookies. Por ello, le facilitamos la
        información y enlaces a los sitos de soporte oficiales de los
        principales navegadores para que pueda decidir si desea o no aceptar el
        uso de cookies.
        <br />
        <br />
        Así, puede bloquear las cookies a través de las herramientas de
        configuración del navegador (todos los navegadores modernos permiten
        cambiar la configuración de Cookies. Estos ajustes normalmente se
        encuentran en las “Opciones” o “Preferencias” del menú del navegador) o
        bien puede configurar su navegador para que le avise cuando un servidor
        quiera guardar una cookie:
        <br />
        <br />
        <ol>
          <li>
            Si utiliza <b>Microsoft Internet Explorer</b>, en la opción de menú
            Herramientas &gt; Opciones de Internet &gt; Privacidad &gt;
            Configuración. Para saber más visite{" "}
            <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
              https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
          <li>
            Si utiliza <b>Mozilla Firefox</b>, en la opción de menú Herramientas
            &gt; Opciones &gt; Privacidad &gt; Cookies. Para saber más visite{" "}
            <a href="https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros">
              https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros
            </a>
          </li>
          <li>
            Si utiliza <b>Google</b> <b>Chrome</b>, en la sección de Opciones
            &gt; Opciones avanzadas &gt; Privacidad. Para saber más visite{" "}
            <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">
              http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
            </a>
          </li>
          <li>
            Si utiliza <b>Opera</b>, en la opción de Seguridad y Privacidad,
            podrá configurar el navegador. Para saber más visite{" "}
            <a href="http://www.opera.com/help/tutorials/security/cookies/">
              http://www.opera.com/help/tutorials/security/cookies/
            </a>
          </li>
          <li>
            Si utiliza <b>Apple Safari</b>, en la opción Preferencias &gt;
            Seguridad o Privacidad, podrá configurar Navegador. Para saber mas
            visite{" "}
            <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">h</a>
            <a href="https://support.apple.com/kb/PH21411?viewlocale=es_ES&amp;locale=es_ES">
              https://support.apple.com/kb/PH21411?viewlocale=es_ES&amp;locale=es_ES
            </a>
          </li>
          <li>
            Si desea deshabilitar <b>Google Universal Analytics</b>, puede
            instalar la siguiente extensión proporcionada por Google:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </li>
        </ol>
        Algunas funcionalidades y servicios pueden quedar deshabilitados o tener
        un comportamiento diferente al esperado como, por ejemplo, permanecer
        identificado, mantener las compras en el “carrito de la compra” en un
        servicio de comercio electrónico o recibir información dirigida a su
        localización, entre otros.
        <br />
        <br />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
